export const STATE_CONTENTS: Record<string, object> = {
    AL: {
        hero:
            "ABC’s all-inclusive Alabama solutions meet 100% of the Alabama Course of Study Standards. Our " +
            "content prepares students for the ACAP assessments and we guarantee our products will improve student " +
            "scores. All workbooks include free access to our digital platform, CourseWave, with eBooks and " +
            "CourseWave Online Testing. Join ABC’s all-inclusive print and digital solution today!",
    },
    AR: {
        hero:
            "ABC’s all-inclusive Arkansas solutions meet 100% of the Arkansas Academic Standards. Our content " +
            "prepares students for the ATLAS assessments and we guarantee our products will improve student scores. " +
            "All workbooks include free access to our digital platform, CourseWave, with eBooks and CourseWave Online " +
            "Testing. Join ABC’s all-inclusive print and digital solution today!",
    },
    DC: {
        hero:
            "ABC’s all-inclusive District of Columbia solutions meet 100% of the Common Core State Standards. " +
            "Our content prepares students for the PARCC assessments and we guarantee our products will improve " +
            "student scores! All print workbooks include free access to the same content in eBook format. Join " +
            "ABC’s all-inclusive print and digital solution today!",
    },
    GA: {
        hero:
            "ABC’s all-inclusive Georgia solutions meet 100% of the Georgia Standards of Excellence (GSE) as well as " +
            "the new Georgia K-12 Mathematics Standards and Georgia K-12 English Language Arts Standards. Our content " +
            "prepares students for the Milestones and BEACON assessments and we guarantee our products will improve " +
            "student scores. All workbooks include free access to our digital platform, CourseWave, with eBooks and " +
            "CourseWave Online Testing. Join ABC’s all-inclusive print and digital solution today!",
    },
    KY: {
        hero:
            "ABC’s all-inclusive Kentucky solutions meet 100% of the Kentucky Academic Standards. Our content " +
            "prepares students for the KSA assessments and we guarantee our products will improve student scores. " +
            "All workbooks include free access to our digital platform, CourseWave, with eBooks and CourseWave " +
            "Online Testing. Join ABC’s all-inclusive print and digital solution today!",
    },
    LA: {
        hero:
            "ABC’s all-inclusive Louisiana solutions meet 100% of the Louisiana Student Standards. " +
            "Our content prepares students for the LEAP 2025 assessments and we guarantee our products will improve " +
            "student scores. All workbooks include free access to our digital platform, CourseWave, with eBooks and " +
            "CourseWave Online Testing. Join ABC’s all-inclusive print and digital solution today!",
    },
    OK: {
        hero:
            "ABC’s all-inclusive Oklahoma solutions meet 100% of the Oklahoma Academic Standards. Our content " +
            "prepares students for the OSTP and CCRA assessments and we guarantee our products will improve student " +
            "scores. All workbooks include free access to our digital platform, CourseWave, with eBooks and " +
            "CourseWave Online Testing. Join ABC’s all-inclusive print and digital solution today!",
    },
    MN: {
        hero:
            "ABC’s all-inclusive Minnesota solutions meet 100% of the Minnesota Academic Standards. Our " +
            "content prepares students for the MCA assessments and we guarantee our products will improve student " +
            "scores! All print workbooks include free access to the same content in eBook format. Join ABC’s " +
            "all-inclusive print and digital solution today!",
    },
    NC: {
        hero:
            "ABC’s all-inclusive North Carolina solutions meet 100% of the North Carolina Standard Course of Study. " +
            "Our content prepares students for the EOG and EOC assessments plus Check-Ins, and we guarantee our " +
            "products will improve student scores. All workbooks include free access to our digital platform, " +
            "CourseWave, with eBooks and CourseWave Online Testing. Join ABC’s all-inclusive print and digital " +
            "solution today!",
    },
    NM: {
        hero:
            "ABC’s all-inclusive New Mexico solutions meet 100% of the Common Core State Standards. Our content " +
            "prepares students for the NM-MSSA assessments and we guarantee our products will improve student " +
            "scores! All print workbooks include free access to the same content in eBook format. Join ABC’s " +
            "all-inclusive print and digital solution today!",
    },
    SC: {
        hero:
            "ABC’s all-inclusive South Carolina solutions meet 100% of the South Carolina College and Career " +
            "Ready Standards. Our content prepares students for the SC Ready, EOCEP, and SCPASS assessments, and " +
            "we guarantee our products will improve student scores! All workbooks include eBooks and online testing! " +
            "Join ABC’s all-inclusive print and digital solution today!",
    },
    TN: {
        hero:
            "ABC’s all-inclusive Tennessee solutions meet 100% of the Tennessee Academic Standards. Our content " +
            "prepares students for the TCAP assessments and we guarantee our products will improve student scores. " +
            "All workbooks include free access to our digital platform, CourseWave, with eBooks and CourseWave Online " +
            "Testing. Join ABC’s all-inclusive print and digital solution today!",
    },
    DEFAULT: {
        hero:
            "<p class='mb-2'>ABC was founded by educators 27 years ago with a vision that has remained consistent " +
            "ever since: creating innovative content to help students achieve academic success.</p>" +
            "<p class='mb-2'>ABC’s all-inclusive solutions give educators tools to customize their lessons to the " +
            "needs of their students without compromising on meeting state or national standards. Our content and " +
            "questions map directly to those standards, and workbook orders include access to our CourseWave digital " +
            "platform, home to our eBooks and Online Testing. CourseWave delivers powerful analytics and instant " +
            "score reporting, empowering educators to deliver data-driven instruction with the ABC content they " +
            "trust.</p>",
    },
};
